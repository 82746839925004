<template>
  <FormKit
    v-model="form"
    type="form"
    :actions="false"
    :incomplete-message="false"
    @submit="handleSubmit"
  >
    <div class="grid gap-y-5">
      <FormKit
        ref="myInput"
        name="title"
        validation="required"
        label="Title"
        type="text"
        input-class="w-full"
      />
      <FormKit
        name="content"
        validation="required"
        label="Content"
        type="textarea"
        help="You can use variables like {{variable}} above. You'll fill them in when you copy the prompt"
        input-class="w-full"
        rows="4"
      />
      <FormKit
        name="category"
        validation="required"
        label="Platform"
        type="select"
        input-class="w-full"
        :options="[
          { label: 'ChatGPT', value: 'chatgpt' },
          { label: 'Claude', value: 'claude' },
          { label: 'Gemini', value: 'gemini' },
          { label: 'Midjourney', value: 'midjourney' },
        ]"
      />
      <div>
        <div class="formkit-label block text-sm font-medium text-gray-700 mb-1">
          <label>Folder</label>
        </div>
        <BaseMultiselect
          v-model="form.folder"
          v-if="folders && folders.data"
          :object="true"
          :options="folders.data.map((row: any) => ({ value: row.id, label: row.name }))"
        />
      </div>
      <div>
        <div class="formkit-label block text-sm font-medium text-gray-700 mb-1">
          <label>Tags</label>
        </div>
        <BaseTagMultiselect
          v-model="form.tags"
          :options="promptTags.data.map((row) => row.value)"
        />
      </div>
      <FormKit
        name="notes"
        label="Notes"
        type="textarea"
        input-class="w-full"
      />
      <div class="flex gap-x-3">
        <BaseButton :loading="submitting">Save</BaseButton>
        <BaseButton
          :loading="submitting"
          icon-left="heroicons:clipboard"
          variant="white"
          @click.prevent="handleSubmitAndCopy"
        >
          Save & Copy
        </BaseButton>
      </div>
      <div v-if="form.updated_at" class="flex text-sm">
        <div class="flex-1 text-gray-400">
          Saved
          {{
            formatDate({
              value: form.updated_at,
              format: 'MMM, D, YYYY [at] h:mma',
            })
          }}
        </div>
        <div v-if="false" class="text-red-500">Remove</div>
      </div>
    </div>
  </FormKit>
</template>

<script setup lang="ts">
import useNotifier from '~/composables/useNotifier'
import formatDate from '~/utils/formatDate'
import useApiFetch from '~/composables/useApiFetch'
import usePromptStoreRefs from '~/composables/usePromptStoreRefs'
import usePromptStore from '~/stores/usePromptStore'
import useEventBus, { EVENTS } from '~/composables/useEventBus'
import useFolderStore from '~/stores/useFolderStore'

const props = defineProps({
  initialValues: {
    type: Object,
    default: () => ({
      id: null,
      title: '',
      content: '',
      folder_id: null,
      tags: [],
      category: 'chatgpt',
    }),
  },
})

const promptStore = usePromptStore()
const myInput = ref(null)
const { notifySuccess } = useNotifier()
const emit = defineEmits(['create', 'update'])
const submitting = ref(false)
const form = ref<any>({
  ...props.initialValues,
  folder: { value: props.initialValues.folder_id },
})
const { promptTags } = usePromptStoreRefs()
const { folders } = useFolderStore()

const handleCreate = async () => {
  const response = await useApiFetch('/prompts', {
    method: 'POST',
    body: {
      ...form.value,
      folder_id: form.value.folder.value,
    },
  })

  notifySuccess({
    title: 'Success',
    text: 'Your prompt was created',
  })

  emit('create', { prompt: response.data.value })

  return response
}

const handleUpdate = async () => {
  const response = await useApiFetch(`/prompts/${form.value.id}`, {
    method: 'PUT',
    body: {
      ...form.value,
      folder_id: form.value.folder.value,
    },
  })

  notifySuccess({
    title: 'Success',
    text: 'Your prompt was updated',
  })

  emit('update', { prompt: response.data.value })

  return response
}

const handleSubmit = async () => {
  submitting.value = true
  let response = null

  try {
    if (form.value.id) {
      response = await handleUpdate()
    } else {
      response = await handleCreate()
    }

    promptStore.fetchPromptTags()
  } catch (err) {
    console.error(err)
    submitting.value = false
  }

  submitting.value = false

  return response
}

const handleSubmitAndCopy = async () => {
  const response = await handleSubmit()
  useEventBus({ name: EVENTS.COPY_PROMPT }).emit({
    prompt: response.data.value,
  })
}
</script>
